/********************
/* S| Inputs
/******************* */
form {
	.form__group {
		margin: .5rem 0;
		margin: 1rem 0;
	}


	.submit {
		outline: none;
		padding: .8rem 2rem;

		background: $orange;
		color: #fff;
		border: none;
		cursor: pointer;
		letter-spacing: 1px;
		text-transform: uppercase;
		transition: background .3s;

		&:hover {
			background: darken($orange, 10%);
			transition: background .3s;
		}
	}
}

label {
	display: flex;
	justify-content: flex-start;
	margin-bottom: .25rem;
}



input[type="text"], input[type="email"], input[type="number"], input[type="phone"], input[type="password"], select, textarea {
	padding: .5rem .5rem;
	width: 100%;
	border: 1px solid $black;
	outline: none;
	font-family: "Winston", sans-serif;
	font-weight: 400;
	font-size: 1rem;
	&:focus {
		outline: 1px solid $blue;
	}
}
input[type='checkbox'] {
	margin-right: 1rem;
}


.checkbox__container {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	&:hover {
		input {
			& ~ .checkbox__mark {
				background-color: $orange;
			}
		}
	}
	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
		&:checked {
			& ~ .checkbox__mark {
				background-color: $orange;
				&:after {
					display: block;
				}
			}
		}

		&.input__error + .checkbox__mark{
			border-color: $danger;
		}

	}

	.checkbox__mark {
		position: absolute;
		top: 0;
		left: 0;
		height: 25px;
		width: 25px;
		border: 1px solid $grey;
		background-color: #eee;

		&:after {
			content: "";
			position: absolute;
			display: none;
			left: 9px;
			top: 5px;
			width: 5px;
			height: 10px;
			border: solid white;
			border-width: 0 3px 3px 0;
			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(45deg);
		}
	}
}

.form__group {
	position: relative;
	margin: 1rem 0;


	.form-control {
		display: flex;
		align-items: stretch;
		justify-content: stretch;

		.after {
			padding: 0 1.5rem;
			background: $light-grey;
			border: 1px solid $grey;
			line-height: 2.5rem;
		}
	}

	.group__display {
		position: relative;
		.input__error {
			border-color: $danger;
			color: $danger;
			&:focus {
				outline-color: $danger;
			}
		}

		.input__success {
			border-color: $success;
			color: $success;
			&:focus {
				outline-color: $success;
			}
		}

		& + .helper {
			&.error {
				color: $danger;
			}
			&.success {
				color: $success
			}
		}

		.display {
			position: absolute;
			bottom: .5rem;
			right: 1rem;
		}
	}

}


/********************
/* S| Modal
/******************* */
/* Modal Content/Box */
.modal {
	display: none;
	position: fixed;
	background-color: #fefefe;
	margin: auto; /* 15% from the top and centered */
	padding: 20px;
	border: 1px solid $grey;
	width: 40%; /* Could be more or less, depending on screen size */
	top: 15%;
	left: calc(-50vw + 50%);
	right: calc(-50vw + 50%);
	z-index: 10000;
	box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

	& + .backdrop {
		display: none;
	}

	&.open {
		display: block;

		& + .backdrop {
			display: block;
			position: fixed;
			top: 0;
			left: 0;
			width: 100vw;
			height: 100vh;
			background: rgba(0, 0, 0, .5);
			z-index: 1000;
		}
	}

	&.modal--small {
		margin: 0 !important;
		left: unset;
		top: unset;
		right: 1rem;
		bottom: 1rem;
		width: 50%;
		border-color: transparent;
		background: $grey-black;
		color: #fff;
		transform: unset;
	}

}

/* The Close Button */
.close {
	display: block;
	color: #aaa;
	float: right;
	font-size: 28px;
	line-height: 40px;
	width: 40px;
	text-align: center;
	font-weight: bold;
}

.close:hover,
.close:focus {
	color: black;
	text-decoration: none;
	cursor: pointer;
}


@media screen and (max-width: map-get($map: $breakpoints, $key: 'l')) {

	.modal {
		width: 80%;

	}
}


/********************
/* S| cards
/******************* */

.card {
	position: relative;
	display: block;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	padding-bottom: 1.5rem;

	.card__new {
		position: absolute;
		top: 0;
		right: -1rem;
		background: $orange;
		color: #fff;
		padding: .2rem .4rem;
		font-size: 1.1rem;
		box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

	}

	.card__promo {
		position: absolute;
		top: 0;
		right: -1rem;
		background: $blue;
		color: #fff;
		padding: .2rem .4rem;
		font-size: 1.1rem;
		box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

	}

	.card__price {
		position: absolute;
		top: 2rem;
		right: -1rem;
		background: $black;
		color: #fff;
		padding: .2rem .4rem;
		box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

	}

	.card__provider {
		font-family: "poppins-sb", sans-serif;
		font-weight: 600;
		font-size: 1.2rem;
		text-transform: capitalize;
		color: $orange;
		text-align: left;
	}

	.card__title {
		color: $black;
		text-align: left;
	}

	.card__picture {
		height: 250px;
		width: 100%;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: contain;
	}
}


/********************
/* S| flash
/******************* */

.alert {
	position: relative;
	background: $grey;
	color: $black;
	width: 100%;
	padding: 1rem;

	.container-s {
		position: relative;

		.close {
			position: absolute;
			top: -.5rem;
			right: -.5rem;
			cursor: pointer;
		}
	}

	&.alert__danger {
		background: $danger;
		color: #fff;
	}

	&.alert__success {
		background: $success;
		color: #fff;
	}
}

/********************
/* S| button
/******************* */

.btn {
	padding: .8rem 2rem;
	line-height: 1rem;
	display: inline-block;
	text-align: center;
	font-size: 1rem;
	font-family: "Winston", sans-serif;
	font-weight: 500;
	outline: none;
	cursor: pointer;
	border: 1px solid transparent;
	background: transparent;
	word-wrap: normal;
	border-radius: 8px;
	&.btn--small {
		padding: .5rem 1rem;
	}
	&.btn__block {
		width: 100%;
	}

	&.btn--shadowed {
		box-shadow: 1px 1px 8px rgba(0,0,0,.4), 0 1px 4px rgba(0,0,0,.4);
	}

	&.btn--primary {
		background: $orange;
		border-color: $orange;
		color: #fff;
		transition: background ease-out .3s;
		&:hover {
			background: darken($orange, 10%);
			transition: background ease-out .3s;
		}
	}

	&.btn--primary-link {
		color: $orange;
	}

	&.btn--outline-primary {
		background: #fff;
		color: $orange;
		border-color: $orange;
		&:hover {
			background: $orange;
			color: #fff;
		}
	}

	&.btn--secondary {
		background: $blue;
		border-color: $blue;
		color: #fff;
	}

	&.btn--secondary-link {
		color: $blue;
	}

	&.btn--outline-secondary {
		background: #fff;
		color: $blue;
		border-color: $blue;
		&:hover {
			background: $blue;
			color: #fff;
		}
	}

	&.btn--danger {
		background: $danger;
		border-color: $danger;
		color: #fff;
	}

	&.btn--danger-link {
		color: $danger;
	}

	&.btn--outline-danger {
		background: #fff;
		color: $danger;
		border-color: $danger;
		&:hover {
			background: $danger;
			color: #fff;
		}
	}

	&.btn--general {
		background: $black;
		border-color: $black;
		color: #fff;
	}

	&.btn--general-link {
		color: $black;
	}

	&.btn--outline-general {
		background: #fff;
		color: $black;
		border-color: $black;
		&:hover {
			background: $black;
			color: #fff;
		}
	}

	&.btn--disabled {
		color: $grey;
		cursor: not-allowed;
	}

}


/********************
/* S| table
/******************* */
.table-responsive {
	overflow-x: auto;
}
.table {
	width: 100%;

	th {
		font-weight: bold;
	}

	td {
		vertical-align: middle;
		line-height: 1.2;
		padding: 1rem;
	}

	&.table__bordered {
		th {
			border: 1px solid $grey-black;
		}
		td {
			border: 1px solid $grey-black;
		}
	}

	&.table__striped {
		tr:nth-child(even){
			background: $light-grey;
		}
	}

	&.table__small {
		td, th {
			padding: .3rem;
		}
	}

}

/********************
/* S| Responsive table
/******************* */
.table__responsive {
	.table__row {
		display: flex;
		justify-content: flex-start;
		transition-duration: .3s;
		flex-wrap: wrap;
		&:not(:last-child) {
			border-bottom: 1px solid $light-grey;
		}
		&:hover {
			background: lighten($light-grey, 3%);
			transition-duration: .3s;
		}
		.table__cell {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			flex: 1;
			line-height: 1.3rem;
			padding: .5rem;
			vertical-align: middle;
			&.cell__action {
				flex: 0;
				i {
					display: inline-block;
					width: 20px;
				}
			}
			span.cell__title {
				font-weight: bold;
			}
		}
	}

	.table__head {
		border-bottom: 2px solid $grey;
	}
	.table__body {
		.cell__title {
			display: none;
		}
	}
}

@media screen and (max-width: map-get($map: $breakpoints, $key: 'l')) {
	.table__responsive {
		.table__head {
			display: none;
		}
		.table__body {
			.cell__title {
				display: block;
				color: $grey;
			}
		}
		.table__row	{
			&:not(:last-child) {
				margin-bottom: 1rem;
			}
			box-shadow: $box-shadow-light;
			&:hover{
				box-shadow: $box-shadow;
			}
			.table__cell {
				display: flex;
				flex-basis: 50%;
				&.cell__action {
					flex: unset;
					justify-content: center;
					align-content: center;
				}
			}
		}
	}
}

/* Dropdown options */

.droptions {
	position: relative;
	.options {
		display: none;
		position: absolute;
		right:0;
		top: calc(100% + 10px);
		flex-direction: column;
		justify-content: flex-start;
		align-content: center;
		width: 160px;
		background-color: #fff;
		z-index: 100;
		box-shadow: $box-shadow;
		a {
			padding: 1rem .5rem;
			transition: color .3s, background .3s;
			&:not(:last-child) {
				border-bottom:1px solid $light-grey ;
			}
			&.success {
				color: $success;
				&:hover {
					background: $success;
					color: #fff;
					transition: color .3s, background .3s;
				}
			}
			&.danger {
				color: $danger;
				&:hover {
					background: $danger;
					color: #fff;
					transition: color .3s, background .3s;
				}
			}
			&.info {
				color: $info;
				&:hover {
					background: $info;
					color: #fff;
					transition: color .3s, background .3s;
				}
			}
			&.warning {
				color: $warning;
				&:hover {
					background: $warning;
					color: #fff;
					transition: color .3s, background .3s;
				}
			}
		}
		&.open {
			display: flex;
		}
	}

}