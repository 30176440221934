
@font-face {
    font-family: "Winston";
    src: url("../fonts/winston/Winston-Black.ttf") format("truetype"),
        url("../fonts/winston/Winston-Black.woff") format('woff'),
        url("../fonts/winston/Winston-Black.woff2") format('woff2');
    font-weight: 1000;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Winston";
    src: url("../fonts/winston/Winston-Bold.ttf") format("truetype"),
        url("../fonts/winston/Winston-Bold.woff") format('woff'),
        url("../fonts/winston/Winston-Bold.woff2") format('woff2');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Winston";
    src: url("../fonts/winston/Winston-Semibold.ttf") format("truetype"),
        url("../fonts/winston/Winston-Semibold.woff") format('woff'),
        url("../fonts/winston/Winston-Semibold.woff2") format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Winston";
    src: url("../fonts/winston/Winston-Medium.ttf") format("truetype"),
        url("../fonts/winston/Winston-Medium.woff") format('woff'),
        url("../fonts/winston/Winston-Medium.woff2") format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Winston";
    src: url("../fonts/winston/Winston-Regular.ttf") format("truetype"),
        url("../fonts/winston/Winston-Regular.woff") format('woff'),
        url("../fonts/winston/Winston-Regular.woff2") format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;

}

@font-face {
    font-family: "Winston";
    src: url("../fonts/winston/Winston-Light.ttf") format("truetype"),
        url("../fonts/winston/Winston-Light.woff") format('woff'),
        url("../fonts/winston/Winston-Light.woff2") format('woff2');
    font-weight: 300; 
    font-style: normal;
    font-display: swap;
}

body {
    font-family: 'Winston', Arial, sans-serif;
    font-weight: 400;
    color: $black; 
    font-size: 16px;
}

h1,h2,h3,h4,h5,h6 {
    font-family: 'Winston', sans-serif;
    margin-top: 0;
    margin-bottom: .5rem;
    font-weight: 900;
    line-height: 1.2;
}

h1 { font-size: 2.5rem; font-weight: 900; }
h2 { font-size: 2rem; font-weight: 900; }
h3 { font-size: 1.75rem; font-weight: 600; }
h4 { font-size: 1.5rem; font-weight: 600; }
h5 { font-size: 1.25rem; font-weight: 600; }
h6 { font-size: 1rem; font-weight: 600; }

strong {
    font-weight: 600;
}

p {
    font-size: 1rem;
    margin-bottom: .2rem;
    & + p {
        margin-top: .8rem;
    }
}

a {
    text-decoration: none;
    font-size: 1rem;
    color: $orange;
}

.link__blue {
    text-decoration: none;
    color: $blue;
}