/********************
/* S| pre header
/******************* */
#pre-header {

	display: flex;
	justify-content: space-between;
	align-content: center;
	align-items: center;

	padding: 10px;
	font-size: 12px;

	background: #fff;
	border-bottom: 1px solid $light-grey;

	&.pre-header__fixed {
		width: 100%;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 1000;
	}

	.pre-header__info {
		color: $black;

		i {
			color: $orange;
		}
	}

	.pre-header__info--open {
		color: $success;
	}

	.pre-header__info--close {
		color: $danger;
	}
}

@media screen and (max-width: map-get($map: $breakpoints, $key: 'm')) {
	#pre-header {
		flex-wrap: wrap;
	}
}


/********************
/* S| header
/******************* */

#header {

	background: #fff;
	border-bottom: 1px solid $grey;
	position: relative;
	z-index: 1000;

	.header__main-box {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 2rem 0;

		img {
			max-width: 312px;
		}
	}

	.header__links {
		display: flex;
		justify-content: space-between;

		.header__link {
			display: flex;
			flex-direction: column;
			text-align: center;
			color: $orange;
			text-decoration: none;
			font-size: .8rem;
			transition: color .3s;
			margin: 0 0 0 2rem;
			font-weight: 600;

			i {
				font-size: 1.6rem;
				margin-bottom: .4rem;
				transition: color .3s;
			}

			img {
				height: 1.65rem;
				width: auto !important;
				margin: 0 auto;
				margin-bottom: .4rem;

			}

			&:hover {
				color: $blue;
				transition: color .3s;

				i {
					color: $orange;
					transition: color .3s;
				}
			}
		}
		.header__dropdown {
			position: relative;
			z-index: 100;
			.link__dropdown {
				display: none;
				flex-direction: column;

				position: absolute;
				width: 90vw;
				max-width: 250px;
				word-wrap: break-word;
				background: #fff;
				z-index: 9999;
				box-shadow: 4px 0px 6px rgba(0, 0, 0, .2);
				top: calc(100% + 1rem);
				right: 0;
				&.open {
					display: flex;
				}

				.dropdown__link {
					display: block;
					color: $blue;
					padding: 1.2rem 1rem;
					transition: color .3s;

					&:not(:last-child) {
						border-bottom: 1px solid $grey;
					}

					&:hover {
						color: $orange;
						transition: color .3s;
					}

				}
			}
		}
	}
}

@media screen and (max-width: map-get($map: $breakpoints, $key: 'm')) {
	#header {

		.responsive-hidden {
			display: none;
		}

		.header__main-box {
			flex-direction: column;
			align-content: center;

			.header__search-box {
				margin-top: 1rem;
				width: 100%;
			}
		}

		.header__links {
			justify-content: space-evenly;
			width: 100%;
			margin-top: 2rem;
		}
	}
}


/**********************
* S| Search box
/********************* */

.header__search-box {
	position: relative;
	display: flex;
	flex-direction: column;
	flex: 1;
	max-width: 400px;

	form {
		display: flex;
		align-items: center;
		flex: 1;
	}

	.search-box__form {
		display: flex;
		flex: 1;
		height: 40px;
		font-size: 0.9rem;
		color: #4a4a4a;
		border: none;
		border-radius: 0;
		position: relative;
		right: 0;
		background-repeat: no-repeat;
		background-position: 1rem 50%;
		background-size: 2rem;
		outline: 0;
		transition: background .3s ease-out, opacity .5s ease-out;

		&:focus {
			background: url(https://cdn1.iconfinder.com/data/icons/hawcons/32/698956-icon-111-search-128.png) no-repeat 1rem 50% #FFFFFF;
			background-size: 2rem;
		}

		.search-box__input {
			display: flex;
			flex: 1;

			background: #fff;
			height: 40px;
			border: 1px solid $grey;
			padding: 0 1rem 0 1rem;
			color: $black;
			font-size: 0.8rem;
			letter-spacing: .1rem;
			outline: 0;
			transition: all .4s ease-out;

			&:focus {
				border-color: $grey;
			}

		}

		.search-box__button {
			background: $orange;
			height: 40px;
			border: none;
			width: 40px;
			color: #FFF;
			font-size: 0.8rem;
			text-transform: uppercase;
			letter-spacing: .1rem;
			cursor: pointer;
			outline: 0;
			transition: all .4s ease-out;

		}

	}

	.search__result {
		display: none;
		position: absolute;
		top: 100%;
		width: 100%;
		justify-content: flex-start;
		flex-direction: column;
		border-left: 1px solid $grey;
		border-right: 1px solid $grey;
		background:#fff;
		z-index: 1000;
		box-shadow: 0px 5px 4px rgba(0, 0, 0, .2);
		&.open {
			display: flex;
		}
		.result {
			padding: 10px 20px;
			border-bottom: 1px solid $grey;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			color: $black;
			transition-duration: .3s;
			img {
				margin-right: 10px;
			}
			&:hover {
				color: $orange;
				background: $light-grey;
				transition-duration: .3s;
			}
		}
	}
}


/**********************
* S| Navbar 
/********************* */


.header__nav-box {


	border: 1px solid $grey;
	background: #fff;

	.nav-box__navbar {
		width: 100%;

		display: flex;
		justify-content: space-between;
	}

	.nav-box__left, .nav-box__right {
		display: flex;
		justify-content: flex-start;
	}

	.nav-box__left {
		.nav-box__dropdown {
			left: 0;
		}

		.nav-box__item {
			&:nth-child(2) {
				.nav-box__link {
					border-left: 1px solid $grey;
				}
			}

		}
	}

	.nav-box__right {
		.nav-box__dropdown {
			right: 0;
		}

		.nav-box__item {
			&:first-child {
				.nav-box__link {
					border-left: 1px solid $grey;
				}
			}

		}
	}

	&.nav-box__fixed {
		position: fixed;
		width: 100%;
		top: 40px;
		z-index: 9999;
		box-shadow: 0px 5px 4px rgba(0, 0, 0, .2);

		.nav-box__item--fixed {
			display: flex;
			align-items: center;
			padding: 0 1rem;
			border-left: 1px solid $grey;
			border-right: 1px solid $grey;
		}
	}


	.nav-box__item {
		cursor: pointer;
		position: relative;


		.nav-box__link {
			display: block;
			padding: 1.5rem .9rem;
			font-weight: 500;
			z-index: 9999;
			transition: color .3s;
			color: $blue;
			text-decoration: none;
			border-right: 1px solid $grey;


			i {
				color: $orange;
				margin-right: .5rem;
			}

			&:hover, .current {
				color: $orange;
				transition: color .3s;

			}
		}


		.nav-box__dropdown {
			display: none;
			flex-direction: column;

			position: absolute;
			width: 90vw;
			max-width: 250px;
			word-wrap: break-word;
			background: #fff;
			z-index: 9999;
			box-shadow: 4px 0px 6px rgba(0, 0, 0, .2);
			top: calc(100% + 1px);

			&.open {
				display: flex;
			}

			.dropdown__link {
				display: block;
				color: $black;
				padding: 1.2rem 1rem;
				transition: color .3s;

				&:not(:last-child) {
					border-bottom: 1px solid $grey;
				}

				&:hover {
					color: $orange;
					transition: color .3s;
				}

			}
		}

		.nav-box__mega.open {
			/* Show the mega menu when hovering the trigger item */
			display: flex;

			.mega__link {
				font-size: 14px;
			}

			.mega__link--title {
				font-size: 18px;
			}
		}

	}

	.nav-box__item--responsive {
		display: none;
	}

	.nav-box__item--fixed {
		display: none;
	}

	.nav-box__mega {
		border: 1px solid $grey;
		border-top: 2px solid $orange;
		margin-top: -1px;
		display: none;
		justify-content: flex-start;
		left: 0px;
		position: absolute;
		top: calc(100% + 1px);
		width: 100%;
		background: #fff;
		z-index: 9;
		justify-content: flex-start;
		box-shadow: 4px 0px 6px rgba(0, 0, 0, .2);

		.open {
			display: flex;
		}

		.mega__aside {
			display: block;
			max-width: 250px;
			width: 25%;

			.mega__aside--link {
				display: block;
				padding: 1rem;
				border-right: 2px solid $grey;
				border-bottom: 1px solid $grey;
				color: $black;
				text-decoration: none;
				color: $blue;

				&:hover, &.open {
					color: $orange;
					border-right-color: $orange;
				}
			}
		}

		.mega__content {
			flex: 1;
			justify-content: space-around;
			flex-wrap: wrap;
			display: none;

			&.open {
				display: flex;
			}

			.mega__content--item {
				padding: 1rem 0;

				h2 a {
					text-decoration: none;
					color: $orange;
					font-size: 22px;
					font-weight: 500;
					margin-bottom: 5px;

					&:hover {
						text-decoration: underline;
					}
				}

				div {
					a {
						display: block;
						text-decoration: none;
						color: $blue;
						font-weight: 500;
						margin-bottom: 5px;
						padding: .5rem 0;

						&:hover {
							text-decoration: underline;
						}
					}
				}

			}
		}
	}
}

@media screen and (max-width: map-get($breakpoints, 'm')) {
	.header__nav-box {
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;
		align-content: center;
		overflow: visible;
		top: 35px !important;

		.nav-box__item--fixed {
			display: none !important;
		}

		.nav-box__item--responsive {
			display: block;
			padding: 1.2rem;
		}

		.nav-box__navbar {
			display: none;
			position: absolute;
			z-index: 99999;
			left: 0;
			top: 100%;

			&.open {
				display: block;
			}

			.nav-box__left {
				display: block;
				background: #fff;

				.nav-box__item {
					display: block;
				}
			}

			.nav-box__right {
				display: block;
				background: #fff;
				right: 0;

				.nav-box__item {
					display: block;
				}
			}

			.nav-box__dropdown {
				left: 0;
				width: 100%;
				max-width: unset;
				position: relative;
			}
		}
	}
}

/********************
/* S| Carousel
/******************* */

.carousel {
	display: flex;
	flex-direction: column;

	.carousel__links {
		display: flex;
		justify-content: space-between;
		margin-bottom: 10px;

		.carousel__links--link {

			display: block;
			padding: 1rem .5rem;
			color: $blue;
			font-weight: 500;
			text-decoration: none;
			background: #fff;
			transition: color .3s;
			width: 100%;
			text-align: center;
			border-bottom: 2px solid $grey;
			transition: border .3s;

			&:hover {
				color: $orange;
				border-bottom: 2px solid $grey;
				transition: border .3s;

			}

			&.open {
				color: $orange;
				border-bottom: 2px solid $orange;
				transition: color .3s;
			}
		}
	}

	.carousel__contents {
		overflow: hidden;
		width: 100%;

		.carousel__contents--content {
			max-height: 630px;
			display: none;
			position: relative;
			background-position: top center;
			background-size: cover;
			background-repeat: no-repeat;

			&.open {
				display: block;
			}

			.caption {
				position: absolute;
				bottom: 20px;
				right: 20px;

				.cta {
					background: $orange;
					display: inline-block;
					padding: 12px 40px;
					color: #fff;
					border: 2px solid $orange;
					box-shadow: 2px 2px 6px rgba(0, 0, 0, .6);
					transition: color .3s, background .3s;

					&:hover {
						background: $blue;
						color: #fff;
						transition: color .3s, background .3s;
					}
				}

			}
		}
	}
}


/********************
/* S| Marques
/******************* */

.brands {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
	grid-gap: 1rem;
	margin: 40px 0;

	> div {
		background: #fff;
		// padding: 1rem;
		display: grid;
		place-items: center;
		overflow: hidden;

		&::before {
			// for apsect ratio
			content: "";
			display: block;
			padding-bottom: 100%;
			grid-area: 1 / 1 / 2 / 2;
		}

		a {
			width: 100%;
			height: 100%;
			object-fit: contain;
			grid-area: 1 / 1 / 2 / 2;

			display: flex;
			align-self: center;
			align-content: center;

			img {
				object-fit: contain;
				width: 100%;
				grid-area: 1 / 1 / 2 / 2;
				transition: transform .3s;
			}

			&:hover {
				img {
					transform: scale(1.4) rotate(20deg);
					transition: transform .3s;
				}
			}
		}
	}
}


.products {
	display: grid;
	grid-template-columns: repeat(5, minmax(150px, 1fr));
	grid-column-gap: 1rem;
	grid-row-gap: 1rem;
	margin: 40px 0;

	.products__item {
		background: #fff;

		.product__card {
			position: relative;
			display: block;
			box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
			padding-bottom: 1.5rem;
			height: 100%;

			.card__new {
				position: absolute;
				top: 0;
				right: 0rem;
				background: $orange;
				color: #fff;
				padding: .2rem .4rem;
				font-size: 1.1rem;
				box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

			}

			.card__promo {
				position: relative;
                display: inline-block;
                float: right;
				background: $blue;
				color: #fff;
                line-height: 19px;
				padding: 0rem .6rem;
				font-size: .7rem;
				box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                transform: translate(5px, -5px);
			}

			.card__price {
				font-weight: 400;
				padding: 1rem 1rem .5rem 1rem;
				color: $orange;
				text-align: left;

				.price__unit {
					font-size: .6rem;
					color: $blue;
				}
				.price__number {
					font-size: 1.2rem;
					line-height: .8;
					color: $orange;
				}
				.price__condition {
					color: $grey-black;
					font-size: .6rem;
				}
			}

			.card__provider {

				font-weight: 500;
				padding: 0 1rem;
				text-transform: capitalize;
				text-align: left;
				font-size: .8rem;
			}

			.product__info {
				padding: 1rem 0;
				border: 1px solid $grey;
				background: $light-grey;
				margin: 1rem 0;
			}

			.card__title {
				color: $black;
				text-align: left;
                font-weight: 500;
                font-size: 0.9rem;
				padding: .5rem 1rem;
			}

			.card__picture {
				height: 250px;
				width: 100%;
				background-position: center center;
				background-repeat: no-repeat;
				background-size: contain;
			}
		}
	}


}

@media screen and (max-width: map-get($breakpoints, m)) {
	.products {
		grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
	}
}


.services {

	display: grid;
	grid-template-columns: repeat(4, minmax(150px, 1fr));
	grid-column-gap: 2rem;
	grid-row-gap: 2rem;

	.services__item {
		padding: 2rem;
		border: 1px solid $light-grey;
		background-color: #fff;
		box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.30);
		transform: translateY(0);
		transition: box-shadow .3s, transform 330ms ease-in-out;

		&:hover {
			box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.8);
			transform: translateY(-20px);
			transition: box-shadow .3s, transform 330ms ease-in-out;
		}

		.services__icon {
			display: inline-block;
			margin: 2rem 0;
		}

		.services__content {

			h4 {
				font-size: 20px;
			}

		}
	}
}

@media screen and (max-width: map-get($breakpoints, 'm')) {
	.services {
		grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
	}
}

#calculator {
	.calculator__inputs {
		padding-right: 2rem;
	}
	.calculator__result {
		padding-left: 2rem;
	}

	h3 { margin: 1rem 0; }
	h4 { margin: 1.5rem 0;}
	.box {
		padding: 2rem;
		box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

		.inputs {
			display: flex;
			justify-content: space-between;
			margin: 1rem 0;
		}

		.calculator-result {
			font-weight: bold;
			color: $orange;
		}

		.unit {
			font-size: 1.2rem;
		}
	}


}



#modal__login {
	background: #fff;
	border: none;
	top: 2.5vh;
	width: 95vw;
	height: 95vh;
	padding: 0;


	.modal-content {
		height: 100%;
		.tabber {
			height: 100%;
			.tab__content {
				display: none;
				justify-content: space-between;
				height: 100%;
				.form__group {
					margin: .6rem 0;
				}
				&.open {
					display: flex;
				}
				.content__left {
					width: 30%;
					height: 100%;
					background: url(/assets/img/background-register.jpg) no-repeat right center;
					background-size: cover;

					a {
						display: inline;
						padding: .8rem 2rem;
						background: $orange;
						color: #fff;
						margin-top: 1rem;
						transition: background .3s;
						&:hover {
							background: darken($orange, 10%);
							transition: background .3s;
						}
					}
				}
				.content__right {
					align-items: center;
					justify-content: center;
					flex-direction: column;

					overflow-y: auto;
					width: 70%;
					height: 100%;
					margin: auto;
					position: relative;

					&.login__section {
						display: none;
						width: 100%;
						&.open, &.register__section {
							display: flex;
						}
					}

					.close {
						position: absolute;
						top:0;
						right:0;
						width: 60px;
						line-height: 60px;
					}

					.content__title {
						display: flex;
						flex-direction: row;
						flex-wrap: wrap;
						justify-content: space-between;
						align-items: center;
						width: 100%;
						margin-bottom: 2rem;
						padding-bottom: 1rem;
						border-bottom: 1px solid $grey;
						h3 {
							margin: 0;
							color: $blue;
							font-weight: 500;
						}
					}


					form {
						width: 60%;
					}

				}
			}
		}
	}
}

.stepper {
	.stepper__heading {
		display: flex;
		justify-content: space-evenly;
		padding: 2rem 0;
		.stepper__item {
			display: flex;
			align-items: center;
			padding: 0 .5rem .5rem .5rem;

			.step__number {
				display: inline-block;
				width: 40px;
				height: 40px;
				line-height: 38px;
				text-align: center;
				font-weight: 900;
				border: 2px solid $grey;
				color: $grey;
				padding: 0;
				border-radius: 100%;
				margin-right: .5rem;
			}
			.step__title {
				font-weight: 600;
				color: $grey;

			}

			&.open {
				.step__number, .step__title {
					border-color: $orange;
					color: $orange;
				}
			}

			&.done {
				.step__number, .step__title {
					border-color: $blue;
					color: $blue;
				}
			}
		}


	}
	.step {
		display: none;
		&.open {
			display: block;
		}
	}
	.stepper__footer {
		.step__button--prev, .step__button--next, .step__submit {
			display: none;
			&.open {
				display: inline-block;
			}
		}
	}
}

@media screen and (max-width: map-get($breakpoints, 'l')) {

	#modal__login {
		background: #fff;
		border: none;
		top: 0;
		width: 100vw;
		height: 100vh;
		overflow: auto;
		padding: 0;

		.modal-content {
			.tabber {
				.tab__content {
					justify-content: flex-start;
					height: 100%;
					.content__left {
						display: none;
					}
					.content__right {
						display: flex;
						justify-content: flex-start;
						padding: 4rem 0 2rem 0;
						width: 100%;
						background: none;
						form {
							width: 90%;
						}
					}
				}
			}
		}
	}

	.stepper {
		.stepper__heading {
			.stepper__item {
				border: none;
				.step__title {
					display: none;
				}
			}
		}
	}
}

@media screen and (max-width: map-get($breakpoints, 'm')) {
	.stepper {
		.stepper__heading {
			.stepper__item {
				border: none;
				.step__title {
					display: none;
				}
			}
		}
	}
}