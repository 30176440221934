section {
    padding: 40px 0;
}

#featured {
    padding: 40px 0;
    background: $light-grey;

    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    animation: slideshow 5s linear infinite;
}

#products {
    background:#fff;
}


#brands {
    background: $light-grey;
}

#services {
    padding: 60px 0;
    background: url('../img/background-section-01.jpg') no-repeat fixed;
    background-size: cover;
}

#footer {
    padding: 2rem 0;
    border-top: 2px solid $light-grey;
}


@keyframes slideshow {
    0%    { background-position: left 20%; }
    50%  { background-position: left 50%; }
    100%  { background-position: left 20%; }
}


#quote {

    .quote__title {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        flex-direction: column;
        p {
            font-size: .8rem;
            margin: .1rem 0;
        }
        h3 {
            margin-top: 1rem;
        }
    }

    table {
        margin-top: 2rem;
        thead {
            border-top: 2px solid $black;
            border-bottom: 2px solid $black;
            tr {

                th {
                    padding: .5rem 1rem;
                    font-size: .8rem;
                }
            }
        }
    }

    .quote__action {
        display: flex;
        justify-content: space-between;
        & > button {
            margin-right: .5rem;
        }
    }


}