/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
*, :after, :before {
  box-sizing: border-box !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1.3; }

ul li, ol li {
  padding: .25rem 0; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

input {
  font-family: 'Winston', serif;
  font-weight: 500; }

.container:after, .container-xl:after, .container-m:after, .container-s:after {
  content: "";
  display: block;
  clear: both; }

.container-xl {
  position: relative;
  display: inherit;
  justify-content: inherit;
  align-content: inherit;
  align-items: inherit;
  flex-direction: inherit;
  margin: 0 auto;
  width: 100%;
  max-width: 1599px; }

.container {
  position: relative;
  display: inherit;
  justify-content: inherit;
  align-content: inherit;
  align-items: inherit;
  flex-direction: inherit;
  margin: 0 auto;
  width: 100%;
  max-width: 1199px; }

.container-m {
  position: relative;
  display: inherit;
  justify-content: inherit;
  align-content: inherit;
  align-items: inherit;
  flex-direction: inherit;
  margin: 0 auto;
  width: 100%;
  max-width: 959px; }

.container-s {
  position: relative;
  display: inherit;
  justify-content: inherit;
  align-content: inherit;
  align-items: inherit;
  flex-direction: inherit;
  margin: 0 auto;
  width: 100%;
  max-width: 679px; }

.container-full {
  position: relative;
  display: inherit;
  justify-content: inherit;
  align-content: inherit;
  align-items: inherit;
  flex-direction: inherit;
  width: 100%;
  padding-right: 5px;
  padding-left: 5px;
  margin-right: auto;
  margin-left: auto; }

@media screen and (max-width: 1199px) {
  .container, .container-full, .container-xl {
    padding: 0 1.5rem; } }

.row {
  display: flex;
  flex-wrap: wrap; }

[class*='col-'] {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 5px;
  padding-left: 5px; }

@media screen and (max-width: 959px) {
  [class*='col-'] {
    margin-bottom: 10px; } }

/* key = xl, l, m, s && break : px*/
@media screen and (min-width: 679px) {
  .col-s-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .col-s-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-s-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-s-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-s-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-s-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-s-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-s-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-s-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-s-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-s-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-s-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .offset-s-11 {
    margin-left: 91.66667%; }
  .offset-s-10 {
    margin-left: 83.33333%; }
  .offset-s-9 {
    margin-left: 75%; }
  .offset-s-8 {
    margin-left: 66.66667%; }
  .offset-s-7 {
    margin-left: 58.33333%; }
  .offset-s-6 {
    margin-left: 50%; }
  .offset-s-5 {
    margin-left: 41.66667%; }
  .offset-s-4 {
    margin-left: 33.33333%; }
  .offset-s-3 {
    margin-left: 25%; }
  .offset-s-2 {
    margin-left: 16.66667%; }
  .offset-s-1 {
    margin-left: 8.33333%; } }

@media screen and (min-width: 959px) {
  .col-m-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .col-m-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-m-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-m-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-m-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-m-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-m-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-m-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-m-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-m-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-m-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-m-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .offset-m-11 {
    margin-left: 91.66667%; }
  .offset-m-10 {
    margin-left: 83.33333%; }
  .offset-m-9 {
    margin-left: 75%; }
  .offset-m-8 {
    margin-left: 66.66667%; }
  .offset-m-7 {
    margin-left: 58.33333%; }
  .offset-m-6 {
    margin-left: 50%; }
  .offset-m-5 {
    margin-left: 41.66667%; }
  .offset-m-4 {
    margin-left: 33.33333%; }
  .offset-m-3 {
    margin-left: 25%; }
  .offset-m-2 {
    margin-left: 16.66667%; }
  .offset-m-1 {
    margin-left: 8.33333%; } }

@media screen and (min-width: 1199px) {
  .col-l-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .col-l-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-l-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-l-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-l-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-l-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-l-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-l-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-l-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-l-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-l-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-l-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .offset-l-11 {
    margin-left: 91.66667%; }
  .offset-l-10 {
    margin-left: 83.33333%; }
  .offset-l-9 {
    margin-left: 75%; }
  .offset-l-8 {
    margin-left: 66.66667%; }
  .offset-l-7 {
    margin-left: 58.33333%; }
  .offset-l-6 {
    margin-left: 50%; }
  .offset-l-5 {
    margin-left: 41.66667%; }
  .offset-l-4 {
    margin-left: 33.33333%; }
  .offset-l-3 {
    margin-left: 25%; }
  .offset-l-2 {
    margin-left: 16.66667%; }
  .offset-l-1 {
    margin-left: 8.33333%; } }

@media screen and (min-width: 1599px) {
  .col-xl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .col-xl-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-1 {
    margin-left: 8.33333%; } }

.p0 {
  padding: 0 !important; }

.py0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.px0 {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.pt0 {
  padding-top: 0 !important; }

.pr0 {
  padding-right: 0 !important; }

.pb0 {
  padding-bottom: 0 !important; }

.pl0 {
  padding-left: 0 !important; }

.p1 {
  padding: 1rem !important; }

.py1 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.px1 {
  padding-left: 1rem !important;
  padding-right: 1rem !important; }

.pt1 {
  padding-top: 1rem !important; }

.pr1 {
  padding-right: 1rem !important; }

.pb1 {
  padding-bottom: 1rem !important; }

.pl1 {
  padding-left: 1rem !important; }

.p2 {
  padding: 2rem !important; }

.py2 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important; }

.px2 {
  padding-left: 2rem !important;
  padding-right: 2rem !important; }

.pt2 {
  padding-top: 2rem !important; }

.pr2 {
  padding-right: 2rem !important; }

.pb2 {
  padding-bottom: 2rem !important; }

.pl2 {
  padding-left: 2rem !important; }

.p3 {
  padding: 3rem !important; }

.py3 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.px3 {
  padding-left: 3rem !important;
  padding-right: 3rem !important; }

.pt3 {
  padding-top: 3rem !important; }

.pr3 {
  padding-right: 3rem !important; }

.pb3 {
  padding-bottom: 3rem !important; }

.pl3 {
  padding-left: 3rem !important; }

.p4 {
  padding: 4rem !important; }

.py4 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important; }

.px4 {
  padding-left: 4rem !important;
  padding-right: 4rem !important; }

.pt4 {
  padding-top: 4rem !important; }

.pr4 {
  padding-right: 4rem !important; }

.pb4 {
  padding-bottom: 4rem !important; }

.pl4 {
  padding-left: 4rem !important; }

.mxauto {
  margin-left: auto !important;
  margin-right: auto !important; }

.myauto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

.mlauto {
  margin-left: auto !important; }

.mrauto {
  margin-right: auto !important; }

.mtauto {
  margin-top: auto !important; }

.mbauto {
  margin-bottom: auto !important; }

.m0 {
  margin: 0 !important; }

.my0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.mx0 {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.mt0 {
  margin-top: 0 !important; }

.mr0 {
  margin-right: 0 !important; }

.mb0 {
  margin-bottom: 0 !important; }

.ml0 {
  margin-left: 0 !important; }

.m1 {
  margin: 1rem !important; }

.my1 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.mx1 {
  margin-left: 1rem !important;
  margin-right: 1rem !important; }

.mt1 {
  margin-top: 1rem !important; }

.mr1 {
  margin-right: 1rem !important; }

.mb1 {
  margin-bottom: 1rem !important; }

.ml1 {
  margin-left: 1rem !important; }

.m2 {
  margin: 2rem !important; }

.my2 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important; }

.mx2 {
  margin-left: 2rem !important;
  margin-right: 2rem !important; }

.mt2 {
  margin-top: 2rem !important; }

.mr2 {
  margin-right: 2rem !important; }

.mb2 {
  margin-bottom: 2rem !important; }

.ml2 {
  margin-left: 2rem !important; }

.m3 {
  margin: 3rem !important; }

.my3 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.mx3 {
  margin-left: 3rem !important;
  margin-right: 3rem !important; }

.mt3 {
  margin-top: 3rem !important; }

.mr3 {
  margin-right: 3rem !important; }

.mb3 {
  margin-bottom: 3rem !important; }

.ml3 {
  margin-left: 3rem !important; }

.m4 {
  margin: 4rem !important; }

.my4 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important; }

.mx4 {
  margin-left: 4rem !important;
  margin-right: 4rem !important; }

.mt4 {
  margin-top: 4rem !important; }

.mr4 {
  margin-right: 4rem !important; }

.mb4 {
  margin-bottom: 4rem !important; }

.ml4 {
  margin-left: 4rem !important; }

.bordered {
  border: 1px solid #e0e0e0; }

@font-face {
  font-family: "Winston";
  src: url("../fonts/winston/Winston-Black.ttf") format("truetype"), url("../fonts/winston/Winston-Black.woff") format("woff"), url("../fonts/winston/Winston-Black.woff2") format("woff2");
  font-weight: 1000;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Winston";
  src: url("../fonts/winston/Winston-Bold.ttf") format("truetype"), url("../fonts/winston/Winston-Bold.woff") format("woff"), url("../fonts/winston/Winston-Bold.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Winston";
  src: url("../fonts/winston/Winston-Semibold.ttf") format("truetype"), url("../fonts/winston/Winston-Semibold.woff") format("woff"), url("../fonts/winston/Winston-Semibold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Winston";
  src: url("../fonts/winston/Winston-Medium.ttf") format("truetype"), url("../fonts/winston/Winston-Medium.woff") format("woff"), url("../fonts/winston/Winston-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Winston";
  src: url("../fonts/winston/Winston-Regular.ttf") format("truetype"), url("../fonts/winston/Winston-Regular.woff") format("woff"), url("../fonts/winston/Winston-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Winston";
  src: url("../fonts/winston/Winston-Light.ttf") format("truetype"), url("../fonts/winston/Winston-Light.woff") format("woff"), url("../fonts/winston/Winston-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

body {
  font-family: 'Winston', Arial, sans-serif;
  font-weight: 400;
  color: #111213;
  font-size: 16px; }

h1, h2, h3, h4, h5, h6 {
  font-family: 'Winston', sans-serif;
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 900;
  line-height: 1.2; }

h1 {
  font-size: 2.5rem;
  font-weight: 900; }

h2 {
  font-size: 2rem;
  font-weight: 900; }

h3 {
  font-size: 1.75rem;
  font-weight: 600; }

h4 {
  font-size: 1.5rem;
  font-weight: 600; }

h5 {
  font-size: 1.25rem;
  font-weight: 600; }

h6 {
  font-size: 1rem;
  font-weight: 600; }

strong {
  font-weight: 600; }

p {
  font-size: 1rem;
  margin-bottom: .2rem; }
  p + p {
    margin-top: .8rem; }

a {
  text-decoration: none;
  font-size: 1rem;
  color: #F39200; }

.link__blue {
  text-decoration: none;
  color: #29235C; }

.text-align-center {
  text-align: center; }

.text-align-left {
  text-align: left; }

.text-align-right {
  text-align: right; }

.text-white {
  color: #fff; }

.text-orange {
  color: #F39200; }

.text-blue {
  color: #29235C; }

.text-success {
  color: #4caf50; }

.text-danger {
  color: #f44336; }

.text-warning {
  color: #ff9800; }

.text-info {
  color: #03a9f4; }

.text-secondary {
  color: #616161; }

.bg-grey {
  background: #f5f5f5; }

.bg-succes {
  background: #4caf50; }

.bg-danger {
  background: #f44336; }

.bg-primary {
  background: #F39200; }

.bg-secondary {
  background: #29235C; }

.text-info {
  color: #03a9f4; }

.text-small {
  font-size: .6rem; }

.text-medium {
  font-size: .8rem; }

@media screen and (max-width: 959px) {
  .mobile-hidden {
    display: none; } }

/********************
/* S| Inputs
/******************* */
form .form__group {
  margin: .5rem 0;
  margin: 1rem 0; }

form .submit {
  outline: none;
  padding: .8rem 2rem;
  background: #F39200;
  color: #fff;
  border: none;
  cursor: pointer;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: background .3s; }
  form .submit:hover {
    background: #c07300;
    transition: background .3s; }

label {
  display: flex;
  justify-content: flex-start;
  margin-bottom: .25rem; }

input[type="text"], input[type="email"], input[type="number"], input[type="phone"], input[type="password"], select, textarea {
  padding: .5rem .5rem;
  width: 100%;
  border: 1px solid #111213;
  outline: none;
  font-family: "Winston", sans-serif;
  font-weight: 400;
  font-size: 1rem; }
  input[type="text"]:focus, input[type="email"]:focus, input[type="number"]:focus, input[type="phone"]:focus, input[type="password"]:focus, select:focus, textarea:focus {
    outline: 1px solid #29235C; }

input[type='checkbox'] {
  margin-right: 1rem; }

.checkbox__container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .checkbox__container:hover input ~ .checkbox__mark {
    background-color: #F39200; }
  .checkbox__container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .checkbox__container input:checked ~ .checkbox__mark {
      background-color: #F39200; }
      .checkbox__container input:checked ~ .checkbox__mark:after {
        display: block; }
    .checkbox__container input.input__error + .checkbox__mark {
      border-color: #f44336; }
  .checkbox__container .checkbox__mark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border: 1px solid #e0e0e0;
    background-color: #eee; }
    .checkbox__container .checkbox__mark:after {
      content: "";
      position: absolute;
      display: none;
      left: 9px;
      top: 5px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg); }

.form__group {
  position: relative;
  margin: 1rem 0; }
  .form__group .form-control {
    display: flex;
    align-items: stretch;
    justify-content: stretch; }
    .form__group .form-control .after {
      padding: 0 1.5rem;
      background: #f5f5f5;
      border: 1px solid #e0e0e0;
      line-height: 2.5rem; }
  .form__group .group__display {
    position: relative; }
    .form__group .group__display .input__error {
      border-color: #f44336;
      color: #f44336; }
      .form__group .group__display .input__error:focus {
        outline-color: #f44336; }
    .form__group .group__display .input__success {
      border-color: #4caf50;
      color: #4caf50; }
      .form__group .group__display .input__success:focus {
        outline-color: #4caf50; }
    .form__group .group__display + .helper.error {
      color: #f44336; }
    .form__group .group__display + .helper.success {
      color: #4caf50; }
    .form__group .group__display .display {
      position: absolute;
      bottom: .5rem;
      right: 1rem; }

/********************
/* S| Modal
/******************* */
/* Modal Content/Box */
.modal {
  display: none;
  position: fixed;
  background-color: #fefefe;
  margin: auto;
  /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #e0e0e0;
  width: 40%;
  /* Could be more or less, depending on screen size */
  top: 15%;
  left: calc(-50vw + 50%);
  right: calc(-50vw + 50%);
  z-index: 10000;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; }
  .modal + .backdrop {
    display: none; }
  .modal.open {
    display: block; }
    .modal.open + .backdrop {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background: rgba(0, 0, 0, 0.5);
      z-index: 1000; }
  .modal.modal--small {
    margin: 0 !important;
    left: unset;
    top: unset;
    right: 1rem;
    bottom: 1rem;
    width: 50%;
    border-color: transparent;
    background: #616161;
    color: #fff;
    transform: unset; }

/* The Close Button */
.close {
  display: block;
  color: #aaa;
  float: right;
  font-size: 28px;
  line-height: 40px;
  width: 40px;
  text-align: center;
  font-weight: bold; }

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer; }

@media screen and (max-width: 1199px) {
  .modal {
    width: 80%; } }

/********************
/* S| cards
/******************* */
.card {
  position: relative;
  display: block;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding-bottom: 1.5rem; }
  .card .card__new {
    position: absolute;
    top: 0;
    right: -1rem;
    background: #F39200;
    color: #fff;
    padding: .2rem .4rem;
    font-size: 1.1rem;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; }
  .card .card__promo {
    position: absolute;
    top: 0;
    right: -1rem;
    background: #29235C;
    color: #fff;
    padding: .2rem .4rem;
    font-size: 1.1rem;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; }
  .card .card__price {
    position: absolute;
    top: 2rem;
    right: -1rem;
    background: #111213;
    color: #fff;
    padding: .2rem .4rem;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; }
  .card .card__provider {
    font-family: "poppins-sb", sans-serif;
    font-weight: 600;
    font-size: 1.2rem;
    text-transform: capitalize;
    color: #F39200;
    text-align: left; }
  .card .card__title {
    color: #111213;
    text-align: left; }
  .card .card__picture {
    height: 250px;
    width: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain; }

/********************
/* S| flash
/******************* */
.alert {
  position: relative;
  background: #e0e0e0;
  color: #111213;
  width: 100%;
  padding: 1rem; }
  .alert .container-s {
    position: relative; }
    .alert .container-s .close {
      position: absolute;
      top: -.5rem;
      right: -.5rem;
      cursor: pointer; }
  .alert.alert__danger {
    background: #f44336;
    color: #fff; }
  .alert.alert__success {
    background: #4caf50;
    color: #fff; }

/********************
/* S| button
/******************* */
.btn {
  padding: .8rem 2rem;
  line-height: 1rem;
  display: inline-block;
  text-align: center;
  font-size: 1rem;
  font-family: "Winston", sans-serif;
  font-weight: 500;
  outline: none;
  cursor: pointer;
  border: 1px solid transparent;
  background: transparent;
  word-wrap: normal;
  border-radius: 8px; }
  .btn.btn--small {
    padding: .5rem 1rem; }
  .btn.btn__block {
    width: 100%; }
  .btn.btn--shadowed {
    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.4), 0 1px 4px rgba(0, 0, 0, 0.4); }
  .btn.btn--primary {
    background: #F39200;
    border-color: #F39200;
    color: #fff;
    transition: background ease-out .3s; }
    .btn.btn--primary:hover {
      background: #c07300;
      transition: background ease-out .3s; }
  .btn.btn--primary-link {
    color: #F39200; }
  .btn.btn--outline-primary {
    background: #fff;
    color: #F39200;
    border-color: #F39200; }
    .btn.btn--outline-primary:hover {
      background: #F39200;
      color: #fff; }
  .btn.btn--secondary {
    background: #29235C;
    border-color: #29235C;
    color: #fff; }
  .btn.btn--secondary-link {
    color: #29235C; }
  .btn.btn--outline-secondary {
    background: #fff;
    color: #29235C;
    border-color: #29235C; }
    .btn.btn--outline-secondary:hover {
      background: #29235C;
      color: #fff; }
  .btn.btn--danger {
    background: #f44336;
    border-color: #f44336;
    color: #fff; }
  .btn.btn--danger-link {
    color: #f44336; }
  .btn.btn--outline-danger {
    background: #fff;
    color: #f44336;
    border-color: #f44336; }
    .btn.btn--outline-danger:hover {
      background: #f44336;
      color: #fff; }
  .btn.btn--general {
    background: #111213;
    border-color: #111213;
    color: #fff; }
  .btn.btn--general-link {
    color: #111213; }
  .btn.btn--outline-general {
    background: #fff;
    color: #111213;
    border-color: #111213; }
    .btn.btn--outline-general:hover {
      background: #111213;
      color: #fff; }
  .btn.btn--disabled {
    color: #e0e0e0;
    cursor: not-allowed; }

/********************
/* S| table
/******************* */
.table-responsive {
  overflow-x: auto; }

.table {
  width: 100%; }
  .table th {
    font-weight: bold; }
  .table td {
    vertical-align: middle;
    line-height: 1.2;
    padding: 1rem; }
  .table.table__bordered th {
    border: 1px solid #616161; }
  .table.table__bordered td {
    border: 1px solid #616161; }
  .table.table__striped tr:nth-child(even) {
    background: #f5f5f5; }
  .table.table__small td, .table.table__small th {
    padding: .3rem; }

/********************
/* S| Responsive table
/******************* */
.table__responsive .table__row {
  display: flex;
  justify-content: flex-start;
  transition-duration: .3s;
  flex-wrap: wrap; }
  .table__responsive .table__row:not(:last-child) {
    border-bottom: 1px solid #f5f5f5; }
  .table__responsive .table__row:hover {
    background: #fdfdfd;
    transition-duration: .3s; }
  .table__responsive .table__row .table__cell {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    line-height: 1.3rem;
    padding: .5rem;
    vertical-align: middle; }
    .table__responsive .table__row .table__cell.cell__action {
      flex: 0; }
      .table__responsive .table__row .table__cell.cell__action i {
        display: inline-block;
        width: 20px; }
    .table__responsive .table__row .table__cell span.cell__title {
      font-weight: bold; }

.table__responsive .table__head {
  border-bottom: 2px solid #e0e0e0; }

.table__responsive .table__body .cell__title {
  display: none; }

@media screen and (max-width: 1199px) {
  .table__responsive .table__head {
    display: none; }
  .table__responsive .table__body .cell__title {
    display: block;
    color: #e0e0e0; }
  .table__responsive .table__row {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 4px; }
    .table__responsive .table__row:not(:last-child) {
      margin-bottom: 1rem; }
    .table__responsive .table__row:hover {
      box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 12px; }
    .table__responsive .table__row .table__cell {
      display: flex;
      flex-basis: 50%; }
      .table__responsive .table__row .table__cell.cell__action {
        flex: unset;
        justify-content: center;
        align-content: center; } }

/* Dropdown options */
.droptions {
  position: relative; }
  .droptions .options {
    display: none;
    position: absolute;
    right: 0;
    top: calc(100% + 10px);
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
    width: 160px;
    background-color: #fff;
    z-index: 100;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 12px; }
    .droptions .options a {
      padding: 1rem .5rem;
      transition: color .3s, background .3s; }
      .droptions .options a:not(:last-child) {
        border-bottom: 1px solid #f5f5f5; }
      .droptions .options a.success {
        color: #4caf50; }
        .droptions .options a.success:hover {
          background: #4caf50;
          color: #fff;
          transition: color .3s, background .3s; }
      .droptions .options a.danger {
        color: #f44336; }
        .droptions .options a.danger:hover {
          background: #f44336;
          color: #fff;
          transition: color .3s, background .3s; }
      .droptions .options a.info {
        color: #03a9f4; }
        .droptions .options a.info:hover {
          background: #03a9f4;
          color: #fff;
          transition: color .3s, background .3s; }
      .droptions .options a.warning {
        color: #ff9800; }
        .droptions .options a.warning:hover {
          background: #ff9800;
          color: #fff;
          transition: color .3s, background .3s; }
    .droptions .options.open {
      display: flex; }

/********************
/* S| pre header
/******************* */
#pre-header {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  padding: 10px;
  font-size: 12px;
  background: #fff;
  border-bottom: 1px solid #f5f5f5; }
  #pre-header.pre-header__fixed {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000; }
  #pre-header .pre-header__info {
    color: #111213; }
    #pre-header .pre-header__info i {
      color: #F39200; }
  #pre-header .pre-header__info--open {
    color: #4caf50; }
  #pre-header .pre-header__info--close {
    color: #f44336; }

@media screen and (max-width: 959px) {
  #pre-header {
    flex-wrap: wrap; } }

/********************
/* S| header
/******************* */
#header {
  background: #fff;
  border-bottom: 1px solid #e0e0e0;
  position: relative;
  z-index: 1000; }
  #header .header__main-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 0; }
    #header .header__main-box img {
      max-width: 312px; }
  #header .header__links {
    display: flex;
    justify-content: space-between; }
    #header .header__links .header__link {
      display: flex;
      flex-direction: column;
      text-align: center;
      color: #F39200;
      text-decoration: none;
      font-size: .8rem;
      transition: color .3s;
      margin: 0 0 0 2rem;
      font-weight: 600; }
      #header .header__links .header__link i {
        font-size: 1.6rem;
        margin-bottom: .4rem;
        transition: color .3s; }
      #header .header__links .header__link img {
        height: 1.65rem;
        width: auto !important;
        margin: 0 auto;
        margin-bottom: .4rem; }
      #header .header__links .header__link:hover {
        color: #29235C;
        transition: color .3s; }
        #header .header__links .header__link:hover i {
          color: #F39200;
          transition: color .3s; }
    #header .header__links .header__dropdown {
      position: relative;
      z-index: 100; }
      #header .header__links .header__dropdown .link__dropdown {
        display: none;
        flex-direction: column;
        position: absolute;
        width: 90vw;
        max-width: 250px;
        word-wrap: break-word;
        background: #fff;
        z-index: 9999;
        box-shadow: 4px 0px 6px rgba(0, 0, 0, 0.2);
        top: calc(100% + 1rem);
        right: 0; }
        #header .header__links .header__dropdown .link__dropdown.open {
          display: flex; }
        #header .header__links .header__dropdown .link__dropdown .dropdown__link {
          display: block;
          color: #29235C;
          padding: 1.2rem 1rem;
          transition: color .3s; }
          #header .header__links .header__dropdown .link__dropdown .dropdown__link:not(:last-child) {
            border-bottom: 1px solid #e0e0e0; }
          #header .header__links .header__dropdown .link__dropdown .dropdown__link:hover {
            color: #F39200;
            transition: color .3s; }

@media screen and (max-width: 959px) {
  #header .responsive-hidden {
    display: none; }
  #header .header__main-box {
    flex-direction: column;
    align-content: center; }
    #header .header__main-box .header__search-box {
      margin-top: 1rem;
      width: 100%; }
  #header .header__links {
    justify-content: space-evenly;
    width: 100%;
    margin-top: 2rem; } }

/**********************
* S| Search box
/********************* */
.header__search-box {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 400px; }
  .header__search-box form {
    display: flex;
    align-items: center;
    flex: 1; }
  .header__search-box .search-box__form {
    display: flex;
    flex: 1;
    height: 40px;
    font-size: 0.9rem;
    color: #4a4a4a;
    border: none;
    border-radius: 0;
    position: relative;
    right: 0;
    background-repeat: no-repeat;
    background-position: 1rem 50%;
    background-size: 2rem;
    outline: 0;
    transition: background .3s ease-out, opacity .5s ease-out; }
    .header__search-box .search-box__form:focus {
      background: url(https://cdn1.iconfinder.com/data/icons/hawcons/32/698956-icon-111-search-128.png) no-repeat 1rem 50% #FFFFFF;
      background-size: 2rem; }
    .header__search-box .search-box__form .search-box__input {
      display: flex;
      flex: 1;
      background: #fff;
      height: 40px;
      border: 1px solid #e0e0e0;
      padding: 0 1rem 0 1rem;
      color: #111213;
      font-size: 0.8rem;
      letter-spacing: .1rem;
      outline: 0;
      transition: all .4s ease-out; }
      .header__search-box .search-box__form .search-box__input:focus {
        border-color: #e0e0e0; }
    .header__search-box .search-box__form .search-box__button {
      background: #F39200;
      height: 40px;
      border: none;
      width: 40px;
      color: #FFF;
      font-size: 0.8rem;
      text-transform: uppercase;
      letter-spacing: .1rem;
      cursor: pointer;
      outline: 0;
      transition: all .4s ease-out; }
  .header__search-box .search__result {
    display: none;
    position: absolute;
    top: 100%;
    width: 100%;
    justify-content: flex-start;
    flex-direction: column;
    border-left: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
    background: #fff;
    z-index: 1000;
    box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.2); }
    .header__search-box .search__result.open {
      display: flex; }
    .header__search-box .search__result .result {
      padding: 10px 20px;
      border-bottom: 1px solid #e0e0e0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: #111213;
      transition-duration: .3s; }
      .header__search-box .search__result .result img {
        margin-right: 10px; }
      .header__search-box .search__result .result:hover {
        color: #F39200;
        background: #f5f5f5;
        transition-duration: .3s; }

/**********************
* S| Navbar 
/********************* */
.header__nav-box {
  border: 1px solid #e0e0e0;
  background: #fff; }
  .header__nav-box .nav-box__navbar {
    width: 100%;
    display: flex;
    justify-content: space-between; }
  .header__nav-box .nav-box__left, .header__nav-box .nav-box__right {
    display: flex;
    justify-content: flex-start; }
  .header__nav-box .nav-box__left .nav-box__dropdown {
    left: 0; }
  .header__nav-box .nav-box__left .nav-box__item:nth-child(2) .nav-box__link {
    border-left: 1px solid #e0e0e0; }
  .header__nav-box .nav-box__right .nav-box__dropdown {
    right: 0; }
  .header__nav-box .nav-box__right .nav-box__item:first-child .nav-box__link {
    border-left: 1px solid #e0e0e0; }
  .header__nav-box.nav-box__fixed {
    position: fixed;
    width: 100%;
    top: 40px;
    z-index: 9999;
    box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.2); }
    .header__nav-box.nav-box__fixed .nav-box__item--fixed {
      display: flex;
      align-items: center;
      padding: 0 1rem;
      border-left: 1px solid #e0e0e0;
      border-right: 1px solid #e0e0e0; }
  .header__nav-box .nav-box__item {
    cursor: pointer;
    position: relative; }
    .header__nav-box .nav-box__item .nav-box__link {
      display: block;
      padding: 1.5rem .9rem;
      font-weight: 500;
      z-index: 9999;
      transition: color .3s;
      color: #29235C;
      text-decoration: none;
      border-right: 1px solid #e0e0e0; }
      .header__nav-box .nav-box__item .nav-box__link i {
        color: #F39200;
        margin-right: .5rem; }
      .header__nav-box .nav-box__item .nav-box__link:hover, .header__nav-box .nav-box__item .nav-box__link .current {
        color: #F39200;
        transition: color .3s; }
    .header__nav-box .nav-box__item .nav-box__dropdown {
      display: none;
      flex-direction: column;
      position: absolute;
      width: 90vw;
      max-width: 250px;
      word-wrap: break-word;
      background: #fff;
      z-index: 9999;
      box-shadow: 4px 0px 6px rgba(0, 0, 0, 0.2);
      top: calc(100% + 1px); }
      .header__nav-box .nav-box__item .nav-box__dropdown.open {
        display: flex; }
      .header__nav-box .nav-box__item .nav-box__dropdown .dropdown__link {
        display: block;
        color: #111213;
        padding: 1.2rem 1rem;
        transition: color .3s; }
        .header__nav-box .nav-box__item .nav-box__dropdown .dropdown__link:not(:last-child) {
          border-bottom: 1px solid #e0e0e0; }
        .header__nav-box .nav-box__item .nav-box__dropdown .dropdown__link:hover {
          color: #F39200;
          transition: color .3s; }
    .header__nav-box .nav-box__item .nav-box__mega.open {
      /* Show the mega menu when hovering the trigger item */
      display: flex; }
      .header__nav-box .nav-box__item .nav-box__mega.open .mega__link {
        font-size: 14px; }
      .header__nav-box .nav-box__item .nav-box__mega.open .mega__link--title {
        font-size: 18px; }
  .header__nav-box .nav-box__item--responsive {
    display: none; }
  .header__nav-box .nav-box__item--fixed {
    display: none; }
  .header__nav-box .nav-box__mega {
    border: 1px solid #e0e0e0;
    border-top: 2px solid #F39200;
    margin-top: -1px;
    display: none;
    justify-content: flex-start;
    left: 0px;
    position: absolute;
    top: calc(100% + 1px);
    width: 100%;
    background: #fff;
    z-index: 9;
    justify-content: flex-start;
    box-shadow: 4px 0px 6px rgba(0, 0, 0, 0.2); }
    .header__nav-box .nav-box__mega .open {
      display: flex; }
    .header__nav-box .nav-box__mega .mega__aside {
      display: block;
      max-width: 250px;
      width: 25%; }
      .header__nav-box .nav-box__mega .mega__aside .mega__aside--link {
        display: block;
        padding: 1rem;
        border-right: 2px solid #e0e0e0;
        border-bottom: 1px solid #e0e0e0;
        color: #111213;
        text-decoration: none;
        color: #29235C; }
        .header__nav-box .nav-box__mega .mega__aside .mega__aside--link:hover, .header__nav-box .nav-box__mega .mega__aside .mega__aside--link.open {
          color: #F39200;
          border-right-color: #F39200; }
    .header__nav-box .nav-box__mega .mega__content {
      flex: 1;
      justify-content: space-around;
      flex-wrap: wrap;
      display: none; }
      .header__nav-box .nav-box__mega .mega__content.open {
        display: flex; }
      .header__nav-box .nav-box__mega .mega__content .mega__content--item {
        padding: 1rem 0; }
        .header__nav-box .nav-box__mega .mega__content .mega__content--item h2 a {
          text-decoration: none;
          color: #F39200;
          font-size: 22px;
          font-weight: 500;
          margin-bottom: 5px; }
          .header__nav-box .nav-box__mega .mega__content .mega__content--item h2 a:hover {
            text-decoration: underline; }
        .header__nav-box .nav-box__mega .mega__content .mega__content--item div a {
          display: block;
          text-decoration: none;
          color: #29235C;
          font-weight: 500;
          margin-bottom: 5px;
          padding: .5rem 0; }
          .header__nav-box .nav-box__mega .mega__content .mega__content--item div a:hover {
            text-decoration: underline; }

@media screen and (max-width: 959px) {
  .header__nav-box {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    overflow: visible;
    top: 35px !important; }
    .header__nav-box .nav-box__item--fixed {
      display: none !important; }
    .header__nav-box .nav-box__item--responsive {
      display: block;
      padding: 1.2rem; }
    .header__nav-box .nav-box__navbar {
      display: none;
      position: absolute;
      z-index: 99999;
      left: 0;
      top: 100%; }
      .header__nav-box .nav-box__navbar.open {
        display: block; }
      .header__nav-box .nav-box__navbar .nav-box__left {
        display: block;
        background: #fff; }
        .header__nav-box .nav-box__navbar .nav-box__left .nav-box__item {
          display: block; }
      .header__nav-box .nav-box__navbar .nav-box__right {
        display: block;
        background: #fff;
        right: 0; }
        .header__nav-box .nav-box__navbar .nav-box__right .nav-box__item {
          display: block; }
      .header__nav-box .nav-box__navbar .nav-box__dropdown {
        left: 0;
        width: 100%;
        max-width: unset;
        position: relative; } }

/********************
/* S| Carousel
/******************* */
.carousel {
  display: flex;
  flex-direction: column; }
  .carousel .carousel__links {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px; }
    .carousel .carousel__links .carousel__links--link {
      display: block;
      padding: 1rem .5rem;
      color: #29235C;
      font-weight: 500;
      text-decoration: none;
      background: #fff;
      transition: color .3s;
      width: 100%;
      text-align: center;
      border-bottom: 2px solid #e0e0e0;
      transition: border .3s; }
      .carousel .carousel__links .carousel__links--link:hover {
        color: #F39200;
        border-bottom: 2px solid #e0e0e0;
        transition: border .3s; }
      .carousel .carousel__links .carousel__links--link.open {
        color: #F39200;
        border-bottom: 2px solid #F39200;
        transition: color .3s; }
  .carousel .carousel__contents {
    overflow: hidden;
    width: 100%; }
    .carousel .carousel__contents .carousel__contents--content {
      max-height: 630px;
      display: none;
      position: relative;
      background-position: top center;
      background-size: cover;
      background-repeat: no-repeat; }
      .carousel .carousel__contents .carousel__contents--content.open {
        display: block; }
      .carousel .carousel__contents .carousel__contents--content .caption {
        position: absolute;
        bottom: 20px;
        right: 20px; }
        .carousel .carousel__contents .carousel__contents--content .caption .cta {
          background: #F39200;
          display: inline-block;
          padding: 12px 40px;
          color: #fff;
          border: 2px solid #F39200;
          box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.6);
          transition: color .3s, background .3s; }
          .carousel .carousel__contents .carousel__contents--content .caption .cta:hover {
            background: #29235C;
            color: #fff;
            transition: color .3s, background .3s; }

/********************
/* S| Marques
/******************* */
.brands {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-gap: 1rem;
  margin: 40px 0; }
  .brands > div {
    background: #fff;
    display: grid;
    place-items: center;
    overflow: hidden; }
    .brands > div::before {
      content: "";
      display: block;
      padding-bottom: 100%;
      grid-area: 1 / 1 / 2 / 2; }
    .brands > div a {
      width: 100%;
      height: 100%;
      object-fit: contain;
      grid-area: 1 / 1 / 2 / 2;
      display: flex;
      align-self: center;
      align-content: center; }
      .brands > div a img {
        object-fit: contain;
        width: 100%;
        grid-area: 1 / 1 / 2 / 2;
        transition: transform .3s; }
      .brands > div a:hover img {
        transform: scale(1.4) rotate(20deg);
        transition: transform .3s; }

.products {
  display: grid;
  grid-template-columns: repeat(5, minmax(150px, 1fr));
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  margin: 40px 0; }
  .products .products__item {
    background: #fff; }
    .products .products__item .product__card {
      position: relative;
      display: block;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      padding-bottom: 1.5rem;
      height: 100%; }
      .products .products__item .product__card .card__new {
        position: absolute;
        top: 0;
        right: 0rem;
        background: #F39200;
        color: #fff;
        padding: .2rem .4rem;
        font-size: 1.1rem;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; }
      .products .products__item .product__card .card__promo {
        position: relative;
        display: inline-block;
        float: right;
        background: #29235C;
        color: #fff;
        line-height: 19px;
        padding: 0rem .6rem;
        font-size: .7rem;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        transform: translate(5px, -5px); }
      .products .products__item .product__card .card__price {
        font-weight: 400;
        padding: 1rem 1rem .5rem 1rem;
        color: #F39200;
        text-align: left; }
        .products .products__item .product__card .card__price .price__unit {
          font-size: .6rem;
          color: #29235C; }
        .products .products__item .product__card .card__price .price__number {
          font-size: 1.2rem;
          line-height: .8;
          color: #F39200; }
        .products .products__item .product__card .card__price .price__condition {
          color: #616161;
          font-size: .6rem; }
      .products .products__item .product__card .card__provider {
        font-weight: 500;
        padding: 0 1rem;
        text-transform: capitalize;
        text-align: left;
        font-size: .8rem; }
      .products .products__item .product__card .product__info {
        padding: 1rem 0;
        border: 1px solid #e0e0e0;
        background: #f5f5f5;
        margin: 1rem 0; }
      .products .products__item .product__card .card__title {
        color: #111213;
        text-align: left;
        font-weight: 500;
        font-size: 0.9rem;
        padding: .5rem 1rem; }
      .products .products__item .product__card .card__picture {
        height: 250px;
        width: 100%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain; }

@media screen and (max-width: 959px) {
  .products {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); } }

.services {
  display: grid;
  grid-template-columns: repeat(4, minmax(150px, 1fr));
  grid-column-gap: 2rem;
  grid-row-gap: 2rem; }
  .services .services__item {
    padding: 2rem;
    border: 1px solid #f5f5f5;
    background-color: #fff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
    transform: translateY(0);
    transition: box-shadow .3s, transform 330ms ease-in-out; }
    .services .services__item:hover {
      box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.8);
      transform: translateY(-20px);
      transition: box-shadow .3s, transform 330ms ease-in-out; }
    .services .services__item .services__icon {
      display: inline-block;
      margin: 2rem 0; }
    .services .services__item .services__content h4 {
      font-size: 20px; }

@media screen and (max-width: 959px) {
  .services {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); } }

#calculator .calculator__inputs {
  padding-right: 2rem; }

#calculator .calculator__result {
  padding-left: 2rem; }

#calculator h3 {
  margin: 1rem 0; }

#calculator h4 {
  margin: 1.5rem 0; }

#calculator .box {
  padding: 2rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; }
  #calculator .box .inputs {
    display: flex;
    justify-content: space-between;
    margin: 1rem 0; }
  #calculator .box .calculator-result {
    font-weight: bold;
    color: #F39200; }
  #calculator .box .unit {
    font-size: 1.2rem; }

#modal__login {
  background: #fff;
  border: none;
  top: 2.5vh;
  width: 95vw;
  height: 95vh;
  padding: 0; }
  #modal__login .modal-content {
    height: 100%; }
    #modal__login .modal-content .tabber {
      height: 100%; }
      #modal__login .modal-content .tabber .tab__content {
        display: none;
        justify-content: space-between;
        height: 100%; }
        #modal__login .modal-content .tabber .tab__content .form__group {
          margin: .6rem 0; }
        #modal__login .modal-content .tabber .tab__content.open {
          display: flex; }
        #modal__login .modal-content .tabber .tab__content .content__left {
          width: 30%;
          height: 100%;
          background: url(/assets/img/background-register.jpg) no-repeat right center;
          background-size: cover; }
          #modal__login .modal-content .tabber .tab__content .content__left a {
            display: inline;
            padding: .8rem 2rem;
            background: #F39200;
            color: #fff;
            margin-top: 1rem;
            transition: background .3s; }
            #modal__login .modal-content .tabber .tab__content .content__left a:hover {
              background: #c07300;
              transition: background .3s; }
        #modal__login .modal-content .tabber .tab__content .content__right {
          align-items: center;
          justify-content: center;
          flex-direction: column;
          overflow-y: auto;
          width: 70%;
          height: 100%;
          margin: auto;
          position: relative; }
          #modal__login .modal-content .tabber .tab__content .content__right.login__section {
            display: none;
            width: 100%; }
            #modal__login .modal-content .tabber .tab__content .content__right.login__section.open, #modal__login .modal-content .tabber .tab__content .content__right.login__section.register__section {
              display: flex; }
          #modal__login .modal-content .tabber .tab__content .content__right .close {
            position: absolute;
            top: 0;
            right: 0;
            width: 60px;
            line-height: 60px; }
          #modal__login .modal-content .tabber .tab__content .content__right .content__title {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            margin-bottom: 2rem;
            padding-bottom: 1rem;
            border-bottom: 1px solid #e0e0e0; }
            #modal__login .modal-content .tabber .tab__content .content__right .content__title h3 {
              margin: 0;
              color: #29235C;
              font-weight: 500; }
          #modal__login .modal-content .tabber .tab__content .content__right form {
            width: 60%; }

.stepper .stepper__heading {
  display: flex;
  justify-content: space-evenly;
  padding: 2rem 0; }
  .stepper .stepper__heading .stepper__item {
    display: flex;
    align-items: center;
    padding: 0 .5rem .5rem .5rem; }
    .stepper .stepper__heading .stepper__item .step__number {
      display: inline-block;
      width: 40px;
      height: 40px;
      line-height: 38px;
      text-align: center;
      font-weight: 900;
      border: 2px solid #e0e0e0;
      color: #e0e0e0;
      padding: 0;
      border-radius: 100%;
      margin-right: .5rem; }
    .stepper .stepper__heading .stepper__item .step__title {
      font-weight: 600;
      color: #e0e0e0; }
    .stepper .stepper__heading .stepper__item.open .step__number, .stepper .stepper__heading .stepper__item.open .step__title {
      border-color: #F39200;
      color: #F39200; }
    .stepper .stepper__heading .stepper__item.done .step__number, .stepper .stepper__heading .stepper__item.done .step__title {
      border-color: #29235C;
      color: #29235C; }

.stepper .step {
  display: none; }
  .stepper .step.open {
    display: block; }

.stepper .stepper__footer .step__button--prev, .stepper .stepper__footer .step__button--next, .stepper .stepper__footer .step__submit {
  display: none; }
  .stepper .stepper__footer .step__button--prev.open, .stepper .stepper__footer .step__button--next.open, .stepper .stepper__footer .step__submit.open {
    display: inline-block; }

@media screen and (max-width: 1199px) {
  #modal__login {
    background: #fff;
    border: none;
    top: 0;
    width: 100vw;
    height: 100vh;
    overflow: auto;
    padding: 0; }
    #modal__login .modal-content .tabber .tab__content {
      justify-content: flex-start;
      height: 100%; }
      #modal__login .modal-content .tabber .tab__content .content__left {
        display: none; }
      #modal__login .modal-content .tabber .tab__content .content__right {
        display: flex;
        justify-content: flex-start;
        padding: 4rem 0 2rem 0;
        width: 100%;
        background: none; }
        #modal__login .modal-content .tabber .tab__content .content__right form {
          width: 90%; }
  .stepper .stepper__heading .stepper__item {
    border: none; }
    .stepper .stepper__heading .stepper__item .step__title {
      display: none; } }

@media screen and (max-width: 959px) {
  .stepper .stepper__heading .stepper__item {
    border: none; }
    .stepper .stepper__heading .stepper__item .step__title {
      display: none; } }

section {
  padding: 40px 0; }

#featured {
  padding: 40px 0;
  background: #f5f5f5;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  animation: slideshow 5s linear infinite; }

#products {
  background: #fff; }

#brands {
  background: #f5f5f5; }

#services {
  padding: 60px 0;
  background: url("../img/background-section-01.jpg") no-repeat fixed;
  background-size: cover; }

#footer {
  padding: 2rem 0;
  border-top: 2px solid #f5f5f5; }

@keyframes slideshow {
  0% {
    background-position: left 20%; }
  50% {
    background-position: left 50%; }
  100% {
    background-position: left 20%; } }

#quote .quote__title {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: column; }
  #quote .quote__title p {
    font-size: .8rem;
    margin: .1rem 0; }
  #quote .quote__title h3 {
    margin-top: 1rem; }

#quote table {
  margin-top: 2rem; }
  #quote table thead {
    border-top: 2px solid #111213;
    border-bottom: 2px solid #111213; }
    #quote table thead tr th {
      padding: .5rem 1rem;
      font-size: .8rem; }

#quote .quote__action {
  display: flex;
  justify-content: space-between; }
  #quote .quote__action > button {
    margin-right: .5rem; }

#product-page .product__information {
  display: flex;
  justify-content: flex-start; }
  #product-page .product__information .product__picture {
    padding: 2rem;
    height: 100%;
    position: relative;
    border-radius: 16px;
    background: #fff; }
    #product-page .product__information .product__picture img {
      position: sticky;
      top: 120px; }
  #product-page .product__information .product__content {
    padding: 2rem; }
    #product-page .product__information .product__content .product__provider {
      padding: 1rem 0; }
      #product-page .product__information .product__content .product__provider img {
        border: 1px solid #e0e0e0; }
    #product-page .product__information .product__content .product__description {
      padding: 2rem 0; }
    #product-page .product__information .product__content .product__promotion .promotion__price {
      font-weight: 600;
      padding: .5rem 0; }
      #product-page .product__information .product__content .product__promotion .promotion__price .unit {
        color: #29235C; }
      #product-page .product__information .product__content .product__promotion .promotion__price .price {
        font-weight: 700;
        font-size: 2rem; }

@media screen and (max-width: 959px) {
  #product-page .product__information {
    display: block; }
    #product-page .product__information .product__picture {
      padding: 0rem; }
      #product-page .product__information .product__picture img {
        position: static; }
    #product-page .product__information .product__content {
      padding: 0rem; } }

#provider-page .links {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 2rem 0; }
  #provider-page .links .links__item {
    background: url("../img/background-orange-small.jpg") no-repeat;
    background-size: cover;
    color: #fff;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 2rem;
    font-weight: 700;
    transform: translateY(0);
    transition: transform 330ms ease-in-out; }
    #provider-page .links .links__item:not(:first-child) {
      margin-left: 2rem; }
    #provider-page .links .links__item i {
      padding: 0  0 2rem 0; }
    #provider-page .links .links__item:hover {
      transform: translateY(-20px);
      transition: transform 330ms ease-in-out; }

.actions--quote {
  display: flex;
  justify-content: flex-end; }
