.container, .container-xl, .container-m, .container-s {
  &:after {
    content: "";
    display: block;
    clear: both;
  }
}

.container-xl {
  position: relative;
  display: inherit;
  justify-content: inherit;
  align-content: inherit;
  align-items: inherit;
  flex-direction: inherit;

  margin: 0 auto;
  width: 100%;
  max-width: map_get($breakpoints, 'xl');
}
.container {
  position: relative;
  display: inherit;
  justify-content: inherit;
  align-content: inherit;
  align-items: inherit;
  flex-direction: inherit;

  margin: 0 auto;
  width: 100%;
  max-width: map_get($breakpoints, 'l');
}
.container-m {
  position: relative;
  display: inherit;
  justify-content: inherit;
  align-content: inherit;
  align-items: inherit;
  flex-direction: inherit;

  margin: 0 auto;
  width: 100%;
  max-width: map_get($breakpoints, 'm');
}
.container-s {
  position: relative;  
  display: inherit;
  justify-content: inherit;
  align-content: inherit;
  align-items: inherit;
  flex-direction: inherit;
  
  margin: 0 auto;
  width: 100%;
  max-width: map_get($breakpoints, 's');
}

.container-full {
  position: relative;
  display: inherit;
  justify-content: inherit;
  align-content: inherit;
  align-items: inherit;
  flex-direction: inherit;

  width: 100%;
  padding-right: $grid-gap/2;
  padding-left: $grid-gap/2;
  margin-right: auto;
  margin-left: auto;
}

@media screen and (max-width: map_get($breakpoints, 'l')) {
  .container, .container-full, .container-xl {
    padding: 0 1.5rem;
  }
}


// line reset
.row {
  display: flex;
  flex-wrap: wrap;
  //margin-right: -$grid-gap;
  //margin-left: -$grid-gap;

}

// clean all the col
[class*='col-']{
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: $grid-gap/2;
  padding-left: $grid-gap/2;
}

@media screen and (max-width: map_get($breakpoints, 'm')) {
  [class*='col-'] {
    margin-bottom: $grid-gap;
  }
}


@mixin grid-col ($index, $coef) {

  .col-#{$index}-#{$coef} {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 (100% / (12 / $coef));
    flex: 0 0 (100% / (12 / $coef));
    max-width: (100% / (12 / $coef));
  }

}

@mixin grid-offset ($index, $coef) {

  .offset-#{$index}-#{$coef} {
    margin-left: (100% / (12 / $coef));
  }

}


/* key = xl, l, m, s && break : px*/
@each $key, $breakpoint in $breakpoints {

  @media screen and (min-width: $breakpoint) {

    @for $i from 12 through 1 {
      @include grid-col($key, $i);
    }

    @for $i from 11 through 1 {
      @include grid-offset($key, $i);
    }

  }

}


// helpers
.p0  { padding: 0!important; }
.py0 { padding-top:0!important;   padding-bottom: 0!important; }
.px0 { padding-left: 0!important; padding-right: 0!important;}
.pt0 { padding-top: 0!important; }
.pr0 { padding-right: 0!important; }
.pb0 { padding-bottom: 0!important; }
.pl0 { padding-left: 0!important; }

.p1  { padding: 1rem!important; }
.py1 { padding-top:1rem!important;   padding-bottom: 1rem!important; }
.px1 { padding-left: 1rem!important; padding-right: 1rem!important;}
.pt1 { padding-top: 1rem!important; }
.pr1 { padding-right: 1rem!important; }
.pb1 { padding-bottom: 1rem!important; }
.pl1 { padding-left: 1rem!important; }

.p2  { padding: 2rem!important; }
.py2 { padding-top:2rem!important;   padding-bottom: 2rem!important; }
.px2 { padding-left: 2rem!important; padding-right: 2rem!important;}
.pt2 { padding-top: 2rem!important; }
.pr2 { padding-right: 2rem!important; }
.pb2 { padding-bottom: 2rem!important; }
.pl2 { padding-left: 2rem!important; }

.p3  { padding: 3rem!important; }
.py3 { padding-top:3rem!important;   padding-bottom: 3rem!important; }
.px3 { padding-left: 3rem!important; padding-right: 3rem!important;}
.pt3 { padding-top: 3rem!important; }
.pr3 { padding-right: 3rem!important; }
.pb3 { padding-bottom: 3rem!important; }
.pl3 { padding-left: 3rem!important; }

.p4  { padding: 4rem!important; }
.py4 { padding-top:4rem!important;   padding-bottom: 4rem!important; }
.px4 { padding-left: 4rem!important; padding-right: 4rem!important;}
.pt4 { padding-top: 4rem!important; }
.pr4 { padding-right: 4rem!important; }
.pb4 { padding-bottom: 4rem!important; }
.pl4 { padding-left: 4rem!important; }

.mxauto { margin-left: auto!important; margin-right: auto!important; }
.myauto { margin-top: auto!important; margin-bottom: auto!important; }
.mlauto { margin-left: auto!important; }
.mrauto { margin-right: auto!important; }
.mtauto { margin-top: auto!important; }
.mbauto { margin-bottom: auto!important; }

.m0  { margin: 0!important; }
.my0 { margin-top:0!important;   margin-bottom: 0!important; }
.mx0 { margin-left: 0!important; margin-right: 0!important;}
.mt0 { margin-top: 0!important; }
.mr0 { margin-right: 0!important; }
.mb0 { margin-bottom: 0!important; }
.ml0 { margin-left: 0!important; }

.m1  { margin: 1rem!important; }
.my1 { margin-top:1rem!important;   margin-bottom: 1rem!important; }
.mx1 { margin-left: 1rem!important; margin-right: 1rem!important;}
.mt1 { margin-top: 1rem!important; }
.mr1 { margin-right: 1rem!important; }
.mb1 { margin-bottom: 1rem!important; }
.ml1 { margin-left: 1rem!important; }

.m2  { margin: 2rem!important; }
.my2 { margin-top:2rem!important;   margin-bottom: 2rem!important; }
.mx2 { margin-left: 2rem!important; margin-right: 2rem!important;}
.mt2 { margin-top: 2rem!important; }
.mr2 { margin-right: 2rem!important; }
.mb2 { margin-bottom: 2rem!important; }
.ml2 { margin-left: 2rem!important; }

.m3  { margin: 3rem!important; }
.my3 { margin-top:3rem!important;   margin-bottom: 3rem!important; }
.mx3 { margin-left: 3rem!important; margin-right: 3rem!important;}
.mt3 { margin-top: 3rem!important; }
.mr3 { margin-right: 3rem!important; }
.mb3 { margin-bottom: 3rem!important; }
.ml3 { margin-left: 3rem!important; }

.m4  { margin: 4rem!important; }
.my4 { margin-top:4rem!important;   margin-bottom: 4rem!important; }
.mx4 { margin-left: 4rem!important; margin-right: 4rem!important;}
.mt4 { margin-top: 4rem!important; }
.mr4 { margin-right: 4rem!important; }
.mb4 { margin-bottom: 4rem!important; }
.ml4 { margin-left: 4rem!important; }

// bordered

.bordered {
  border: 1px solid $grey;
}