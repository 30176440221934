#product-page {
    .product__information {
        display: flex;
        justify-content: flex-start;
        .product__picture {
            padding: 2rem;
            height: 100%;
            position: relative;
            border-radius: 16px;
            background: #fff;
            img {
                position: sticky;
                top: 120px;
            }
        }
        .product__content {
            padding: 2rem;
            .product__provider {
                padding: 1rem 0;
                img {
                border: 1px solid $grey;
                }
            }
            .product__description {
                padding: 2rem 0;
            }

            .product__promotion {
                .promotion__price {
                    font-weight: 600;
                    padding: .5rem 0;
                    .unit {
                        color: $blue;
                    }

                    .price {
                        font-weight: 700;
                        font-size: 2rem;
                    }
                }
            }
        }
    }
}


@media screen and (max-width: map-get($breakpoints, m )) {

    #product-page {
        .product__information {
            display: block;
            .product__picture {
                padding: 0rem;
                img {
                    position: static;
                }
            }
            .product__content {
                padding: 0rem;

            }
        }
    }

}


#provider-page {
    .links {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin: 2rem 0;
        .links__item {
            background: url("../img/background-orange-small.jpg") no-repeat;
            background-size: cover;
            color: #fff;
            display: flex;
            flex-direction: column;
            text-align: center;
            padding: 2rem;
            font-weight: 700;
            &:not(:first-child) {
                margin-left: 2rem;
            }
            i {
                padding: 0  0 2rem 0;

            }
            transform: translateY(0);
            transition: transform 330ms ease-in-out;

            &:hover {
                transform: translateY(-20px);
                transition: transform 330ms ease-in-out;
            }

        }
    }
}

.actions--quote {
    display: flex;
    justify-content: flex-end;
}